// 广告定时策略模块
import request from '@/utils/request'

//  查询初始化(首页)
export  function pageInit() {
   return request({
     url: "/api/ad/scheduled/pageInit",
     method: "get"
   })
}

// 查询分页
export function getListPage(params) {
   return request({
     url: "/api/ad/scheduled/listPage",
     params,
     method: "get"
   })
}

// 查询应用对象
export function listObjectByType(params) {
   return request({
     url: "/api/ad/scheduled/listObjectByType",
     params,
     method: "get"
   })
}

// 查询应用对象初始化
export function listObjectInit(params) {
   return request({
     url: "/api/ad/scheduled/listObjectInit",
     params,
     method: "get"
   })
}

// 定时策略新增
export function addScheduled(data) {
   return request({
     url: "/api/ad/scheduled",
     data,
     method: "post"
   })
}

// 修改任务状态
export function updateStatusById(data) {
  return request({
    url: "/api/ad/scheduled/updateStatus",
    data,
    method: "post"
  })
}

// 编辑初始化
export function getEditInit(id) {
  return request({
    url: "/api/ad/scheduled/updateInit/" + id,
    method: "get"
  })
}

// 定时策略编辑
export function editScheduled(data) {
  return request({
    url: "/api/ad/scheduled",
    data,
    method: "put"
  }) 
}

// 执行记录查询分页
export function getRecordListPage(params) {
  return request({
    url: "/api/amzad/record/listPage",
    params,
    method: "get"
  })
}

// 广告操作记录详情
export function getOpRecorddetail(params) {
  return request({
    url: "/api/amzad/record/item/listPage",
    params,
    method: "get"
  }) 
}

// 执行结果页面查询查询分页
export function getresultListPage(params) {
  return request({
     url: "/api/amzad/record/item/result/listPage",
     params,
     method: "get"
  })
}
